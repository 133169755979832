/* eslint-disable jsx-a11y/anchor-is-valid */
import ReCAPTCHA from "react-google-recaptcha";
import React, { useState }  from 'react';
//import { makeStyles } from '../../makeStyle';
import { styled } from '@mui/material/styles';
import { Button, TextField, Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
//import { FaGoogle, FaFacebook} from 'react-icons/fa';
//import { GoogleLogin } from 'react-google-login';
//import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import config from '../../config';

var jwtDecode = require('jwt-decode').default;


const host = config.host;
const idpHost = config.idpHost;

const Root = styled('div')(({ theme }) => ({
  minHeight: "100vh",
  width: "100vw",
  backgroundImage: "url('images/background.webp')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Container = styled('div')(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
  minHeight: "40%",
  maxHeight: "40%",
  width: "15%"
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));


const LoginForm = ({ setIsAuthenticated, setUserId }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [is2FAEnabled, set2FAEnabled] = useState(false);
  const [temporaryAccessToken, setTemporaryAccessToken] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertCode, setShowAlertCode] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [showAlertRecaptcha, setShowAlertRecaptcha] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    /*if (recaptchaValue === '') {
      setShowAlertRecaptcha(true);
      return;
    }*/
    try {
      setShowAlert(false);
      const url = `${process.env.NODE_ENV === 'development'? `http://${host}:4000`: `https://${idpHost}/idp`}/login`;
      const response = await axios.post(url, { username, password});

      if (response.status === 200) {
        console.log(
          'First step of authentication succeeded',
          response.data.accessToken
        );
        set2FAEnabled(true);
        setTemporaryAccessToken(response.data.accessToken);
      } else {
        console.log('Authentication failed');
        setShowAlert(true);
      }
    } catch (error) {
      console.error(error);
      setShowAlert(true);
    }
  };

  const handleVerificationCodeSubmit = async () => {
    try {
      setShowAlertCode(false);
      const decoded = jwtDecode(temporaryAccessToken);
      const userId = decoded.id;
      const url = `${process.env.NODE_ENV === 'development'? `http://${host}:4000`: `https://${idpHost}/idp`}/verify`;
      const response = await axios.post(url, { userId: userId, code: verificationCode });

      if (response.status === 200) {
        console.log('2FA succeeded');
        setIsAuthenticated(true);
        setUserId(decoded.id);
        set2FAEnabled(false);
        setTemporaryAccessToken('');
        localStorage.setItem('accessToken', response.data.accessToken);
      } else {
        console.log('2FA failed');
        setShowAlertCode(true);
      }
    } catch (error) {
      console.error(error);
      setShowAlertCode(true);
    }
  };

  const handleGoogleSuccess = (response) => {
    console.log(response);
  };

  const handleGoogleFailure = (error) => {
    console.error(error);
  };

  const handleFacebookResponse = (response) => {
    console.log(response);
  };

  return (
    <Root>
      <Container>
        <Title variant="h4">Login</Title>
        {is2FAEnabled ? (
          <TextFieldStyled
            fullWidth
            label="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        ) : (
          <>
            <TextFieldStyled
              fullWidth
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextFieldStyled
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </>
        )}
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={is2FAEnabled ? handleVerificationCodeSubmit : handleSubmit}
        >
          {is2FAEnabled ? 'Verify Code' : 'Login'}
        </Button>
        {showAlert && (
          <Alert severity="error">
            Combinaison utilisateur/mot de passe inconnue
          </Alert>
        )}
        {/*{showAlertCode && <Alert severity="error">Code incorrect</Alert>}
        <ReCAPTCHA
          sitekey="YourRecaptchaSiteKey"
          onChange={(value) => setRecaptchaValue(value)}
        />
        {showAlertRecaptcha && (
          <Alert severity="error">Veuillez cocher la case Recaptcha.</Alert>
        )}
        <Grid container spacing={2}>
          <Grid item>
            <GoogleLogin
              clientId="YourGoogleClientId"
              onSuccess={handleGoogleSuccess}
              onFailure={handleGoogleFailure}
              cookiePolicy={'single_host_origin'}
            >
              <FaGoogle size={20} />
              <span> Login with Google</span>
            </GoogleLogin>
          </Grid>
          <Grid item>
            <FacebookLogin
              appId="YourFacebookAppId"
              autoLoad={false}
              fields="name,email,picture"
              callback={handleFacebookResponse}
              icon={<FaFacebook size={20} />}
            />
          </Grid>
        </Grid>
        */}        
      </Container>
    </Root>
  );
};

export default LoginForm;