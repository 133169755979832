import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../contexts/UserContext";
import { styled } from "@mui/material/styles";
import { makeStyles } from "../../makeStyle";
import axios from "axios";

import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CalculateIcon from "@mui/icons-material/Calculate";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import config from "../../config";

const host = config.host;
const idpHost = config.idpHost;

//const appUser = config.appUser;
/*
table: {
  minWidth: 650,
},
bold: {
  fontWeight: "bold",
},

libUser: {
  color: "black",
  fontWeight: "bold",
  fontSize: "26px",
  display: "inline-block",
  width: "300px",
},
libUserTotalRA: {
  color: "black",
  fontWeight: "bold",
  fontSize: "26px",
  display: "inline-block",
  width: "200px",
  textAlign: "right",
},
totalCommissionLibNEGO: {
  color: "darkgreen",
  fontWeight: "bold",
  fontSize: "26px",
  display: "inline-block",
  width: "170px",
},
totalCommissionNEGO: {
  color: "darkgreen",
  fontWeight: "bold",
  fontSize: "26px",
  display: "inline-block",
  width: "90px",
  textAlign: "right",
},
totalCommissionValNEGO: {
  color: "darkgreen",
  fontWeight: "bold",
  fontSize: "26px",
  display: "inline-block",
  width: "150px",
  textAlign: "right",
},
totalCommissionRA: {
  color: "darkred",
  fontWeight: "bold",
  fontSize: "26px",
  display: "inline-block",
  width: "90px",
  textAlign: "right",
},
totalCommissionLibRA: {
  color: "darkred",
  fontWeight: "bold",
  fontSize: "26px",
  display: "inline-block",
  width: "170px",
},
totalCommissionValRA: {
  color: "darkred",
  fontWeight: "bold",
  fontSize: "26px",
  display: "inline-block",
  width: "150px",
  textAlign: "right",
},

accordionCom: {
  border: "2px solid darkgreen",
  margin: "2px",
},
accordionComRA: {
  border: "2px solid darkred",
},
accordionSummary: {
  whiteSpace: "nowrap",
  // autres styles...
},
*/

const spanTotalCommissionValRA = styled("span")({
  color: "darkred",
  fontWeight: "bold",
  fontSize: "26px",
  display: "inline-block",
  width: "150px",
  textAlign: "right",
});



const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  bold: {
    fontWeight: "bold",
  },
  /*container: {
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: "36px",
  },*/
  libUser: {
    color: "black",
    fontWeight: "bold",
    fontSize: "26px",
    display: "inline-block",
    width: "300px",
  },
  libUserTotalRA: {
    color: "black",
    fontWeight: "bold",
    fontSize: "26px",
    display: "inline-block",
    width: "200px",
    textAlign: "right",
  },
  totalCommissionLibNEGO: {
    color: "darkgreen",
    fontWeight: "bold",
    fontSize: "26px",
    display: "inline-block",
    width: "170px",
  },
  totalCommissionNEGO: {
    color: "darkgreen",
    fontWeight: "bold",
    fontSize: "26px",
    display: "inline-block",
    width: "90px",
    textAlign: "right",
  },
  totalCommissionValNEGO: {
    color: "darkgreen",
    fontWeight: "bold",
    fontSize: "26px",
    display: "inline-block",
    width: "150px",
    textAlign: "right",
  },
  totalCommissionRA: {
    color: "darkred",
    fontWeight: "bold",
    fontSize: "26px",
    display: "inline-block",
    width: "90px",
    textAlign: "right",
  },
  totalCommissionLibRA: {
    color: "darkred",
    fontWeight: "bold",
    fontSize: "26px",
    display: "inline-block",
    width: "170px",
  },
  totalCommissionValRA: {
    color: "darkred",
    fontWeight: "bold",
    fontSize: "26px",
    display: "inline-block",
    width: "150px",
    textAlign: "right",
  },

  accordionCom: {
    border: "2px solid darkgreen",
    margin: "2px",
  },
  accordionComRA: {
    border: "2px solid darkred",
  },
  accordionSummary: {
    whiteSpace: "nowrap",
    // autres styles...
  },
});

function Salaires() {
  const appUser = useContext(UserContext);
  const classes = useStyles();

  //const appUser = config.appUser.trim();
  const currentDate = dayjs();
  const [startDate, setStartDate] = useState(currentDate);
  const [salaryData, setSalaryData] = useState([]);

  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [totalCommission, setTotalCommission] = useState(0);
  const [agences, setAgences] = useState([]);

  const defaultUserIndex = users.findIndex((user) => user.idusers === appUser);

  const [selectedUserId, setSelectedUserId] = useState(
    defaultUserIndex >= 0 ? users[defaultUserIndex].idusers : ""
  );

  const defaultAgenceIndex = agences.findIndex(
    (agence) =>
      agence.idAgence ===
      users.find((user) => user.idusers === appUser).refAgence
  );

  const [selectedAgenceId, setSelectedAgenceId] = useState(
    defaultAgenceIndex >= 0 ? agences[defaultAgenceIndex].idAgence : ""
  );

  const [groupedSalaryData, setGroupedSalaryData] = useState({});

  const UserCell = ({ refUser }) => {
    const [userName, setUserName] = useState("");
    const isRefUserDefined = refUser !== undefined;

    useEffect(() => {
      if (isRefUserDefined) {
        const user = users.find((user) => user.idusers === refUser);
        if (user) {
          setUserName(`${user.userFirstName} ${user.userName}`);
        }
      }
    }, [isRefUserDefined, refUser]);

    return (
      <span className={!isRefUserDefined ? "invalidUser" : ""}>
        {isRefUserDefined ? userName : "[Non renseigné]"}
      </span>
    );
  };

  useEffect(() => {
    axios
      .get(//`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/users/list`
             `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/users/list`)
      .then((response) => {
        if (response.data.usersList) {
          setUsers(response.data.usersList);
        } else {
          console.log("Aucune donnée reçue");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(//`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/agences/list`
             `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/agences/list`)
      .then((response) => {
        if (response.data.agenceList) {
          setAgences(response.data.agenceList);
        } else {
          console.log("Aucune donnée reçue");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setSelectedUserId(appUser);
  }, []);

  useEffect(() => {
    const appUserObj = users.find((user) => user.idusers === appUser);
    if (appUserObj) {
      setSelectedAgenceId(appUserObj.refAgence);
    } else {
      console.warn(`No user found with idusers = ${appUser}`);
    }
  }, []);

  /*const handleGetSalaryData = async () => {
    try {
      const response = await axios.get(
        `${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/salary/calcul`,
        {
          params: {
            userRef: selectedUserId,
            salaryDate: startDate.format("YYYY-MM-DD"),
          },
        }
      );
      const salaryData = response.data.salaryData;

      // Calculer la somme de la colonne valComm
      const sum = salaryData.reduce((acc, row) => acc + row.valComm, 0);
      setTotalCommission(sum);

      // Récupérer le libellé de l'utilisateur courant
      const user = users.find((who) => who.idusers === appUser);
      console.log(
        `${selectedUserId} ${user?.userFirstName} ${user?.userName} ${user}`
      );
      setCurrentUser(`${user?.userFirstName} ${user?.userName}`);
      setSalaryData(salaryData);
    } catch (error) {
      console.log(error);
    }
  };
  */

  function groupByUser(salaryData) {
    return salaryData.reduce((groups, item) => {
      const user = item.refuser;
      if (!groups[user]) {
        groups[user] = [];
      }
      groups[user].push(item);
      return groups;
    }, {});
  }

  const groupedData = salaryData.reduce((acc, curr) => {
    if (!acc[curr.refuser]) {
      acc[curr.refuser] = {
        isra0: [],
        isra1: [],
      };
    }
    if (curr.isra === "0") {
      acc[curr.refuser].isra0.push(curr);
    } else {
      acc[curr.refuser].isra1.push(curr);
    }
    return acc;
  }, {});

  // Calculer la somme de la colonne valComm pour chaque utilisateur
  // Calculer la somme de la colonne valComm pour chaque utilisateur et chaque isra
  const groupedSums = salaryData.reduce((acc, row) => {
    acc[row.refuser] = acc[row.refuser] || { isra0: 0, isra1: 0 };
    acc[row.refuser][`isra${row.isra}`] += row.valComm;
    return acc;
  }, {});

  const handleGetSalaryData = async () => {
    try {
      const response = await axios.get(
        //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/salary/calculagence`,
          `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/salary/calculagence`,
        {
          params: {
            refAgence: selectedAgenceId,
            salaryDate: startDate.format("YYYY-MM-DD"),
          },
        }
      );
      const salaryData = response.data.salaryData;

      // Calculer la somme de la colonne valComm
      const sum = salaryData.reduce((acc, row) => acc + row.valComm, 0);
      setTotalCommission(sum);

      // Récupérer le libellé de l'utilisateur courant
      const user = users.find((who) => who.idusers === appUser);
      console.log(
        `${selectedUserId} ${user?.userFirstName} ${user?.userName} ${user}`
      );
      setCurrentUser(`${user?.userFirstName} ${user?.userName}`);
      setSalaryData(salaryData);
      const groupedSalaryData = groupByUser(salaryData);
      setGroupedSalaryData(groupedSalaryData);
    } catch (error) {
      console.log(error);
    }
  };

  /*useEffect(() => {
    handleGetSalaryData();
  }, [startDate]);
  */

  const columns = [
    /*{ field: "salaryMonth", headerName: "Mois", width: 150 },*/
    { field: "quarterName", headerName: "Trimestre", width: 150 },
    /*{
      field: "comTrimAvantActe",
      headerName: "Com Trim Avant Acte",
      type: "number",
      width: 120,
      valueFormatter: (params) => {
        return new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(params.value);
      },
    },
    {
      field: "partHonoraires",
      headerName: "Part Honoraires",
      type: "number",
      width: 150,
      valueFormatter: (params) => {
        return new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(params.value);
      },
    },
    */
    {
      field: "libCom",
      headerName: "% Com",
      width: 60,
      cellClassName: classes.bold,
      headerClassName: classes.bold,
    },
    /*{
      field: "pourcentComm",
      headerName: "% Commission",
      type: "number",
      width: 150,
      valueFormatter: (params) => {
        return new Intl.NumberFormat("fr-FR", {
          style: "percent",
        }).format(params.value / 100);
      },
    },*/
    {
      field: "valComm",
      headerName: "Val Comm",
      type: "number",
      width: 150,
      valueFormatter: (params) => {
        return new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(params.value);
      },
      cellClassName: classes.bold,
      headerClassName: classes.bold,
    },
    {
      field: "mergedColumns0",
      headerName: "Dossier",
      width: 430,
      renderCell: (params) => {
        return (
          <div>
            <span className={"numDossier"}>{params.row.dossier}</span>
            <span> : </span>
            <span className={"saleName"}>{params.row.saleName}</span>
            <span> / </span>
            <span className={"buyerName"}>{params.row.buyerName}</span>
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Prix",
      width: 150,
      align: "right", // Ajouter cette ligne
      cellClassName: classes.cellRightPadding, // Ajouter cette ligne
      headerAlign: "center", // Ajouter cette ligne si vous voulez également aligner l'en-tête de colonne
      valueFormatter: (params) => {
        return new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(params.value);
      },
    },
    {
      field: "commission",
      headerName: "Honoraires",
      width: 150,
      align: "right", // Ajouter cette ligne
      headerAlign: "center", // Ajouter cette ligne si vous voulez également aligner l'en-tête de colonne
      cellClassName: classes.cellRightPadding, // Ajouter cette ligne
      valueFormatter: (params) => {
        return new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(params.value);
      },
    },
    {
      field: "mergedColumns",
      headerName: "Entrée",
      width: 420,
      renderCell: (params) => {
        const date = new Date(params.row.dateCompromis); // Convertir la dateCompromis en objet Date
        const options = { day: "2-digit", month: "2-digit", year: "numeric" }; // Définir les options de formatage pour la date
        const formattedDate = date.toLocaleDateString("fr-FR", options); // Formater la date en utilisant les options de formatage
        const isInvalidDate = formattedDate === "01/01/1970";
        const displayDate = isInvalidDate ? "[Non définie]" : formattedDate;
        return (
          <div>
            <span className={isInvalidDate ? "invalidDate" : "dateActe"}>
              {displayDate}
            </span>
            <span> : </span>
            <span className="pourcent">
              {new Intl.NumberFormat("fr-FR", {
                style: "percent",
              }).format(params.row.percentMandatUser / 100)}
            </span>
            <span> = </span>
            <span className="prixUnderMillion">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                (params.row.commission * params.row.percentMandatUser) / 100
              )}
            </span>
            <span> : </span>
            <span>
              <UserCell refUser={params.row.refMandatUser} />
            </span>
          </div>
        );
      },
      cellClassName: (params) => {
        return params.row?.refMandatUser?.trim() === selectedUserId?.trim()
          ? "highlightedCell"
          : "";
      },
    },
    {
      field: "mergedColumns2",
      headerName: "Sortie",
      width: 420,
      renderCell: (params) => {
        const date = new Date(params.row.dateActePrevu); // Convertir la dateActePrevu en objet Date
        const dateActe = new Date(params.row.dateActe); // Convertir la dateActePrevu en objet Date

        const options = { day: "2-digit", month: "2-digit", year: "numeric" }; // Définir les options de formatage pour la date
        let formattedDate = date.toLocaleDateString("fr-FR", options); // Formater la date en utilisant les options de formatage
        const formattedDateActe = dateActe.toLocaleDateString("fr-FR", options);

        if (formattedDateActe !== "01/01/1970") {
          formattedDate = formattedDateActe;
        }
        const isInvalidDate = formattedDate === "01/01/1970";

        const displayDate = isInvalidDate ? "[Non définie]" : formattedDate;

        return (
          <div>
            <span
              className={
                isInvalidDate
                  ? "invalidDate"
                  : formattedDateActe !== "01/01/1970"
                  ? "dateActe"
                  : "date"
              }
            >
              {displayDate}
            </span>
            <span> : </span>
            <span className="pourcent">
              {new Intl.NumberFormat("fr-FR", {
                style: "percent",
              }).format(params.row.percentSaleUser / 100)}
            </span>
            <span> = </span>
            <span className="prixUnderMillion">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                (params.row.commission * params.row.percentSaleUser) / 100
              )}
            </span>
            <span> : </span>
            <span>
              <UserCell refUser={params.row.refSaleUser} />
            </span>
          </div>
        );
      },
      cellClassName: (params) => {
        return params.row?.refSaleUser?.trim() === selectedUserId?.trim()
          ? "highlightedCell2"
          : "";
      },
    },
  ];

  return (
    <div >
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2} style={{ paddingBottom: 20 }} alignItems="center">
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGetSalaryData}
                startIcon={<CalculateIcon />}
              >
                Calculer
              </Button>
            </Grid>
            <Grid item xs={2}>
              <DatePicker
                label="Mois de salaire"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                format="DD/MM/YYYY"
                views={["year", "month", "day"]}
                slotProps={{ textField: { size: "small" } }}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                select
                label="Agence"
                value={selectedAgenceId}
                onChange={(event) => setSelectedAgenceId(event.target.value)}
                variant="outlined"
                style={{
                  marginBottom: 0,
                  width: "100%",
                  verticalAlign: "middle",
                }}
                fullWidth
                size="small"
              >
                {agences.map((agence) => (
                  <MenuItem key={agence.idAgence} value={agence.idAgence}>
                    {agence.agNom}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                label="Utilisateur"
                value={selectedUserId}
                onChange={(event) => setSelectedUserId(event.target.value)}
                variant="outlined"
                style={{
                  marginBottom: 0,
                  width: "100%",
                  verticalAlign: "middle",
                }}
                fullWidth
                size="small"
              >
                {users.map((user) => (
                  <MenuItem key={user.idusers} value={user.idusers}>
                    {user.userFirstName} {user.userName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </LocalizationProvider>
        {Object.entries(groupedData).map(([refuser, isras], index) => (
          <Accordion key={refuser} className={classes.accordionCom}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" justifyContent="space-between">
                <Typography className={classes.libUser}>
                  {
                    users.find((user) => user.idusers === refuser)
                      ?.userFirstName
                  }{" "}
                  {users.find((user) => user.idusers === refuser)?.userName}
                </Typography>
                <Typography className={classes.totalCommissionLibNEGO}>
                  {groupedSums[refuser]?.isra0?.toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 2,
                  })}
                </Typography>
                {groupedSums[refuser]?.isra1 &&
                Number(groupedSums[refuser]?.isra1) !== 0 ? (
                  <Typography className={classes.totalCommissionRA}>
                    {groupedSums[refuser]?.isra1?.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                ) : null}
                {groupedSums[refuser]?.isra1 &&
                Number(groupedSums[refuser]?.isra1) !== 0 ? (
                  <Typography className={classes.libUserTotalRA}>
                    ={" "}
                    {(
                      groupedSums[refuser]?.isra1 + groupedSums[refuser]?.isra0
                    )?.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                ) : null}
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              <Accordion className={classes.accordionCom} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="accordion-content"
                  id="accordion-header"
                >
                  <Typography>
                    <span className={classes.totalCommissionLibNEGO}>
                      Commissions
                    </span>
                    <span className={classes.totalCommissionNEGO}>
                      Négo. :{" "}
                    </span>

                    <span className={classes.totalCommissionValNEGO}>
                      {groupedSums[refuser]?.isra0?.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DataGrid
                    columnResizing
                    pageSize={50}
                    rows={isras.isra0}
                    columns={columns}
                  />
                </AccordionDetails>
              </Accordion>
              {groupedSums[refuser]?.isra1 ? (
                <Accordion className={classes.accordionComRA} defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="accordion-content"
                    id="accordion-header"
                  >
                    <Typography c>
                      <span className={classes.totalCommissionLibRA}>
                        Commissions
                      </span>

                      <span className={classes.totalCommissionRA}>R.A. :</span>
                     <span className={classes.totalCommissionValRA}>
                        {groupedSums[refuser]?.isra1?.toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DataGrid
                      columnResizing
                      pageSize={50}
                      rows={isras.isra1}
                      columns={columns}
                    />
                  </AccordionDetails>
                </Accordion>
              ) : null}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

export default Salaires;
