import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Grid, Input } from '@mui/material';
import { createStyles, makeStyles } from '../../makeStyle';

import config from '../../config';

const host = config.host;
const idpHost = config.idpHost;


const useStyles = makeStyles(() =>{
  
    
}
);


function VenteImportXls() {
    const classes = useStyles();

  const [file, setFile] = useState(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const cols = json[0].map((col, index) => ({
        field: `col${index}`,
        headerName: col,
        width: 150,
      }));

      const dataRows = json.slice(1).map((row, index) => {
        const rowData = {};
        row.forEach((cell, i) => {
          rowData[`col${i}`] = cell;
        });
        return { id: index, ...rowData };
      });

      setColumns(cols);
      setRows(dataRows);
    };

    if (file) {
      reader.readAsArrayBuffer(file);
    }
    };

  const handleImport = () => {
    if (!file) {
      alert("Veuillez sélectionner un fichier");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
  
    axios
      .post(
          //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/importxls`,
          `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/importxls`,
        formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log(response.data);
        alert('Importation réussie');
      })
      .catch((error) => {
        console.error(error);
        alert('Erreur lors de l\'importation');
      });
  };

  return (
    <div className={classes.root}>
    <Grid container direction="column" style={{ height: '100%' }}>
      <Grid item>
        <Box p={2}>
          <Input type="file" onChange={handleFileChange} accept=".xls,.xlsx" color="primary" 
           
           endDecorator={<Button>Message</Button>}/>
        </Box>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Box height="100%" width="100%" overflow="auto">
          <DataGrid rows={rows} columns={columns} pageSize={20} />
        </Box>
      </Grid>
      
      <Grid item>
        <Box p={2}>
        <Button onClick={handleImport} color="primary">Importer</Button>
        </Box>
      </Grid>
    </Grid>
    
    </div>
  );
}

export default VenteImportXls;