import React, { useState, useEffect } from "react";
import { makeStyles } from '../../makeStyle';
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FormattedNumber } from "react-intl";
import {
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  InputAdornment,
  Grid,
  MenuItem,
  FormControl ,
  InputLabel ,
  Select 
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from 'dayjs/esm/plugin/utc';


import { frFR } from "@mui/x-data-grid";
import frLocale from "date-fns/locale/fr";

import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import config from "../../config";

const host = config.host;
const idpHost = config.idpHost;


dayjs.extend(quarterOfYear);
dayjs.extend(utc);

const appUser = config.appUser;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    minHeight: 750,
  },
  modal: {
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 16,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  cellRightPadding: {
    paddingRight: "1% !important",
  },
  highlightedRow: {
    backgroundColor: "#e6ffe6",
  },
  
});

const getStartDateOfPreviousQuarter = () => {
  const currentDate = dayjs(); // Date actuelle
  const currentQuarter = currentDate.quarter(); // Trimestre actuel

  let previousQuarter;
  let year;

  if (currentQuarter === 1) {
    previousQuarter = 4;
    year = currentDate.year() - 1;
  } else {
    previousQuarter = currentQuarter - 1;
    year = currentDate.year();
  }

  return dayjs().year(year).quarter(previousQuarter).startOf("quarter");
};

function VenteListe() {
  const classes = useStyles();
  const [sales, setSales] = useState([]);
  const [selectedSale, setSelectedSale] = useState({});
  const [open, setOpen] = useState(false);
  const [updatedSales, setUpdatedSales] = useState({});
  const [selectedUserAdd, setSelectedUserAdd] = useState('');
  const [editedSale, setEditedSale] = useState({
    saleName: "",
    price: 0,
    commission: 0,
  });
  const [newSale, setNewSale] = useState({
    dossier: "",
    saleName: "",
    buyerName: "",
    price: 0,
    commission: 0,
    refMandatUser: "",
    percentMandatUser: 0,
    refSaleUser: "",
    percentSaleUser: 0,
    refSaler: "",
    refBuyer: "",
    isExclusif: false,
    dateCompromis: null,
    dateActePrevu: null,
    dateActe: null,
    datePaiementAgence: null,
  });
  const [addSaleDialogOpen, setAddSaleDialogOpen] = useState(false);

  const handleOpenAddSaleDialog = () => {
    setAddSaleDialogOpen(true);
  };

  const handleCloseAddSaleDialog = () => {
    setAddSaleDialogOpen(false);
  };

  const handleInputChangesetAddSaleDialog = (event) => {
    setNewSale({
      ...newSale,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === 'refSaleUser') {
      setSelectedUserAdd(event.target.value);
    }
  };

  const handleSaveAddSaleDialog = async () => {
    try {
      const response = await fetch(
        //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/sales/salenew`,
        `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/sales/salenew`,
       {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newSale),
      });
      if (!response.ok) {
        throw new Error("Error adding new sale");
      }
      //filterSales(startDate, endDate) // Refresh the sale list after adding the new sale
      fetchFilteredSales();
      handleCloseAddSaleDialog();
    } catch (error) {
      console.error(error);
    }
  };

  
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [users, setUsers] = useState([]);

  const currentDate = dayjs();
  const [startDate, setStartDate] = useState(getStartDateOfPreviousQuarter());
  const [endDate, setEndDate] = useState(currentDate);

  const defaultUserIndex = users.findIndex((user) => user.IDUSERS === appUser);
  const [selectedUserId, setSelectedUserId] = useState(
    defaultUserIndex >= 0 ? users[defaultUserIndex].IDUSERS : ""
  );

  function filterSales(startDate, endDate) {
    const encodedStartDate = encodeURIComponent(startDate.toISOString());
    const encodedEndDate = encodeURIComponent(endDate.toISOString());
    axios
      .get(
        //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/sales/list?startDate=${encodedStartDate}&endDate=${encodedEndDate}`
        `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/sales/list?startDate=${encodedStartDate}&endDate=${encodedEndDate}`,
      )
      .then((response) => {
        if (response.data.salesData) {
          //console.log(response.data.salesData);
          setSales(response.data.salesData);
        } else {
          console.log("Aucune donnée reçue");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchFilteredSales() {
    const encodedStartDate = encodeURIComponent(startDate.toISOString());
    const encodedEndDate = encodeURIComponent(endDate.toISOString());
    axios
      .get(
        //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/sales/list?startDate=${encodedStartDate}&endDate=${encodedEndDate}`
        `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/sales/list?startDate=${encodedStartDate}&endDate=${encodedEndDate}`,
      )
      .then((response) => {
        if (response.data.salesData) {
          //console.log(response.data.salesData);
          setSales(response.data.salesData);
        } else {
          console.log("Aucune donnée reçue");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /*
  useEffect(() => {
    filterSales(startDate, endDate);
  }, []);
  */

  useEffect(() => {
    fetchFilteredSales();
  }, []);
  

  
  useEffect(() => {
    axios
      .get(
          //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/users/list`
          `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/users/list`,
      )
      .then((response) => {
        if (response.data.usersList) {
          setUsers(response.data.usersList);
        } else {
          console.log("Aucune donnée reçue");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setSelectedUserId(appUser);
  }, []);

  const UserCell = ({ refUser }) => {
    const [userName, setUserName] = useState("");
    const isRefUserDefined = refUser !== undefined;
  
    useEffect(() => {
      if (isRefUserDefined) {
        const user = users.find((user) => user.idusers === refUser);
        if (user) {
          setUserName(`${user.userFirstName} ${user.userName}`);
        }
      }
    }, [isRefUserDefined, refUser]);
  
    return <span className={!isRefUserDefined ? "invalidUser" : ""}>{isRefUserDefined ? userName : "[Non renseigné]"}</span>;
  };

  const handleOpen = (sale) => {
    setSelectedSale(sale);
    setOpen(true);
    setEditedSale({
      saleName: sale.saleName,
      price: sale.price,
      commission: sale.commission,
    });
  };

  const handleDelete = (row) => {
    setSelectedSale(row);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    axios
      .put(
          //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/sales/saledel/${selectedSale.idsale}`
          `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/sales/saledel/${selectedSale.idsale}`,
      )
      .then(() => {
        console.log("Supprimé");
        setSales(sales.filter((sale) => sale.idsale !== selectedSale.idsale));
        setSelectedSale({});
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleModalInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedSale({ ...selectedSale, [name]: value });
  };


  /*const handleModalInputChangeAddSale = (event) => {
    const { name, value } = event.target;
    setNewSale({ ...newSale, [name]: value });
  };
  */

  const handleModalClose = () => {
    setSelectedSale({});
    setOpen(false);
  };

  const handleModalSave = () => {
    axios
      .put(
        `${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/sales/saleupd/${selectedSale.idsale}`,
        selectedSale
      )
      .then(() => {
        console.log("updated");
        setSales(
          sales.map((sale) =>
            sale.idsale === selectedSale.idsale ? selectedSale : sale
          )
        );
        setSelectedSale({});
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleApplyFilter = () => {
    fetchFilteredSales();
  };

  const columns = [
    /*{ field: 'id', headerName: 'ID', width: 70 },*/
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
      disableColumnMenu: true, 
    },
    {
      field: "mergedColumns0",
      headerName: "Dossier",
      width: 430,
      renderCell: (params) => {
        return (
          <div>
            <span className={"numDossier"}>{params.row.dossier }</span>
            <span> : </span>
            <span className={"saleName"}>{params.row.saleName }</span>
            <span> / </span>
            <span className={"buyerName"}>{params.row.buyerName }</span>
          </div>  
        );
      }
    },
    {
      field: "price",
      headerName: "Prix",
      width: 150,
      align: "right", // Ajouter cette ligne
      cellClassName: classes.cellRightPadding, // Ajouter cette ligne
      headerAlign: "center", // Ajouter cette ligne si vous voulez également aligner l'en-tête de colonne
      valueFormatter: (params) => {
        return new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(params.value);
      },
    },
    { 
      field: "commission",
      headerName: "Honoraires",
      width: 150,
      align: "right", // Ajouter cette ligne
      headerAlign: "center", // Ajouter cette ligne si vous voulez également aligner l'en-tête de colonne
      cellClassName: classes.cellRightPadding, // Ajouter cette ligne
      valueFormatter: (params) => {
        return new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(params.value);
      },
    },
    {
      field: "mergedColumns",
      headerName: "Entrée",
      width: 420,
      renderCell: (params) => {
        const date = new Date(params.row.dateCompromis); // Convertir la dateCompromis en objet Date
        const options = { day: "2-digit", month: "2-digit", year: "numeric" }; // Définir les options de formatage pour la date
        const formattedDate = date.toLocaleDateString("fr-FR", options); // Formater la date en utilisant les options de formatage
        const isInvalidDate = formattedDate === "01/01/1970"; 
        const displayDate = isInvalidDate ? "[Non définie]" : formattedDate;
        return (
          <div>
            <span className={isInvalidDate ? "invalidDate" : "dateActe"}>{displayDate }</span>
            <span> : </span>
            <span className="pourcent">
              {new Intl.NumberFormat("fr-FR", {
                style: "percent",
              }).format(params.row.percentMandatUser / 100)}
            </span>
            <span> = </span>
            <span className="prixUnderMillion">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                (params.row.commission * params.row.percentMandatUser) / 100
              )}
            </span>
            <span> : </span>
            <span>
              <UserCell refUser={params.row.refMandatUser} />
            </span>
          </div>
        );
      },
      cellClassName: (params) => {
        return params.row?.refMandatUser?.trim() === selectedUserId?.trim()
          ? "highlightedCell"
          : "";
      },
    },
    {
      field: "mergedColumns2",
      headerName: "Sortie",
      width: 420,
      renderCell: (params) => {
        const date = new Date(params.row.dateActePrevu); // Convertir la dateActePrevu en objet Date
        const dateActe = new Date(params.row.dateActe); // Convertir la dateActePrevu en objet Date
        
        const options = { day: "2-digit", month: "2-digit", year: "numeric" }; // Définir les options de formatage pour la date
        let formattedDate = date.toLocaleDateString("fr-FR", options); // Formater la date en utilisant les options de formatage
        const formattedDateActe = dateActe.toLocaleDateString("fr-FR", options);
        
        if(formattedDateActe !== "01/01/1970"){
          formattedDate = formattedDateActe;
          
        }
        const isInvalidDate = formattedDate === "01/01/1970";  
        
        const displayDate = isInvalidDate ? "[Non définie]" : formattedDate;
        
        
        return (
          <div >
            <span className={isInvalidDate ? "invalidDate" : (formattedDateActe !== "01/01/1970" ? "dateActe" : "date")}>{displayDate }</span>
            <span> : </span>
            <span className="pourcent">
              {new Intl.NumberFormat("fr-FR", {
                style: "percent",
              }).format(params.row.percentSaleUser / 100)}
            </span>
            <span> = </span>
            <span className="prixUnderMillion">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                (params.row.commission * params.row.percentSaleUser) / 100
              )}
            </span>
            <span> : </span>
            <span>
              <UserCell refUser={params.row.refSaleUser} />
            </span>
          </div>
        );
      },
      cellClassName: (params) => {
        return params.row?.refSaleUser?.trim() === selectedUserId?.trim()
          ? "highlightedCell2"
          : "";
      },
    },
  ];

  const dateCompromis = selectedSale.dateCompromis ? dayjs(selectedSale.dateCompromis) : null;
  const dateActePrevu = selectedSale.dateActePrevu ? dayjs(selectedSale.dateActePrevu) : null;
  const dateActe = selectedSale.dateActe ? dayjs(selectedSale.dateActe) : null;

  return (
    <div >
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}  >
          <Grid container spacing={2} style={{paddingBottom: 20}} alignItems="center">
            <Grid item xs={1} >
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenAddSaleDialog}
                startIcon={<AddIcon />}
              >
                Ajouter
              </Button>
            </Grid>
            <Grid item xs={1} style={{ paddingLeft: 20 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilter}
                startIcon={<FilterAltIcon />}
              >
                Appliquer
              </Button>
            </Grid>

            <Grid item xs={2}>
              <DatePicker
                label="Date de début"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                format="DD/MM/YYYY"
                views={["year", "month", "day"]}
                slotProps={{ textField: { size: 'small' } }}
              />
            </Grid>
            <Grid item xs={2}>
              <DatePicker
                label="Date de fin"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                format="DD/MM/YYYY"
                views={["year", "month", "day"]}
                slotProps={{ textField: { size: 'small' } }}                
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                label="Utilisateur"
                value={selectedUserId}
                onChange={(event) => setSelectedUserId(event.target.value)}
                variant="outlined"
                style={{
                  marginBottom: 0,
                  width: "100%",
                  verticalAlign: "middle",
                }}
                fullWidth
                size="small"
              >
                {users.map((user) => (
                  <MenuItem key={user.idusers} value={user.idusers}>
                    {user.userFirstName} {user.userName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </LocalizationProvider>
      <DataGrid
        columnResizing
        rows={sales}
        columns={columns}
        pageSize={50}
        getRowId={(row) => row.idsale}
        style={{ height: "100vh" }}

        /*getRowClassName={(params) => {
            return classes.highlightedRow;
          if (
            params.row.refMandatUser === appUser ||
            params.row.refSaleUser === appUser
          ) {
            return classes.highlightedRow;
          }
          return "";
        }}*/
      />
            </div>
      <Dialog open={open} onClose={handleModalClose}>
        <DialogTitle>Modifier la vente</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="dossier"
            label="Dossier"
            type="text"
            fullWidth
            value={selectedSale.dossier || ""}
            onChange={handleModalInputChange}
          />
          <TextField
            margin="dense"
            name="saleName"
            label="Vendeur"
            type="text"
            fullWidth
            value={selectedSale.saleName || ""}
            onChange={handleModalInputChange}
          />
          <TextField
            margin="dense"
            name="buyerName"
            label="Acquéreur"
            type="text"
            fullWidth
            value={selectedSale.buyerName || ""}
            onChange={handleModalInputChange}
          />
          <TextField
            margin="dense"
            name="price"
            label="Prix"
            type="number"
            fullWidth
            value={selectedSale.price || ""}
            onChange={handleModalInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            name="commission"
            label="Honoraires"
            type="number"
            fullWidth
            value={selectedSale.commission || ""}
            onChange={handleModalInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container direction="column" spacing={2}>
            <Grid item></Grid>
            <Grid item></Grid>
              <Grid item>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="select-mandat-user-label">Entrée</InputLabel>
                  <Select
                    labelId="select-mandat-user-label"
                    id="select-mandat-user"
                    name="refMandatUser"
                    value={selectedSale.refMandatUser || ""}
                    onChange={handleModalInputChange}
                  >
                    {users.map((user) => (
                      <MenuItem key={user.idusers} value={user.idusers}>
                        {user.userFirstName} {user.userName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  margin="dense"
                  name="percentMandatUser"
                  label="Commission Mandat"
                  type="number"
                  fullWidth
                  value={selectedSale.percentMandatUser || ""}
                  onChange={handleModalInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
                <DatePicker
                  label="Date de compromis"
                  value={dateCompromis}
                  onChange={(date) => {
                    console.log(`date compromis change modal : ${date}`);
                    handleModalInputChange({
                      target: {
                        name: "dateCompromis",
                        value: date,
                      },
                    });
                  }}
                  format="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
              <Grid item></Grid>
              <Grid item></Grid>            
              <Grid item>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="select-sale-user-label">Sortie</InputLabel>
                  <Select
                    labelId="select-sale-user-label"
                    id="select-sale-user"
                    name="refSaleUser"
                    value={selectedSale.refSaleUser || ""}
                    onChange={handleModalInputChange}
                  >
                    {users.map((user) => (
                      <MenuItem key={user.idusers} value={user.idusers}>
                        {user.userFirstName} {user.userName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  margin="dense"
                  name="percentSaleUser"
                  label="% Commission Vente"
                  type="number"
                  fullWidth
                  value={selectedSale.percentSaleUser || ""}
                  onChange={handleModalInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>  
              <Grid item>
                <DatePicker
                  label="Date d'acte prévu"
                  value={dateActePrevu}
                  onChange={(date) => {
                    handleModalInputChange({
                      target: {
                        name: "dateActePrevu",
                        value: date,
                      },
                    });
                  }}
                  format="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  label="Date d'acte"
                  value={dateActe}
                  onChange={(date) => {
                    handleModalInputChange({
                      target: {
                        name: "dateActe",
                        value: date,
                      },
                    });
                  }}
                  format="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleModalSave} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Voulez-vous vraiment annuler la vente ?
            <br />
            Dossier : {selectedSale.dossier}
            <br />
            Vendeur : {selectedSale.saleName}
            <br />
            Acquéreur : {selectedSale.buyerName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addSaleDialogOpen} onClose={handleCloseAddSaleDialog}>
        <DialogTitle>Ajouter une vente</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="dossier"
            label="Dossier"
            type="text"
            fullWidth
            value={newSale.dossier}
            onChange={handleInputChangesetAddSaleDialog}
          />
          <TextField
            margin="dense"
            name="saleName"
            label="Vendeur"
            type="text"
            fullWidth
            value={newSale.saleName}
            onChange={handleInputChangesetAddSaleDialog}
          />
          <TextField
            margin="dense"
            name="buyerName"
            label="Acquéreur"
            type="text"
            fullWidth
            value={newSale.buyerName}
            onChange={handleInputChangesetAddSaleDialog}
          />
          <TextField
            margin="dense"
            name="price"
            label="Prix"
            type="number"
            fullWidth
            value={newSale.price}
            onChange={handleInputChangesetAddSaleDialog}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            name="commission"
            label="Honoraires"
            type="number"
            fullWidth
            value={newSale.commission}
            onChange={handleInputChangesetAddSaleDialog}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container direction="column" spacing={2}>
            <Grid item></Grid>
            <Grid item></Grid>
              <Grid item>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="select-mandat-user-label">Entrée</InputLabel>
                  <Select
                    labelId="select-mandat-user-label"
                    id="select-mandat-user"
                    name="refMandatUser"
                    value={newSale.refMandatUser || ""}
                    onChange={handleInputChangesetAddSaleDialog}
                  >
                    {users.map((user) => (
                      <MenuItem key={user.idusers} value={user.idusers}>
                        {user.userFirstName} {user.userName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  margin="dense"
                  name="percentMandatUser"
                  label="Commission Mandat"
                  type="number"
                  fullWidth
                  value={newSale.percentMandatUser || ""}
                  onChange={handleInputChangesetAddSaleDialog}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
                <DatePicker
                  label="Date de compromis"
                  onChange={(date) => {
                    console.log(`date compromis change modal : ${date}`);
                    handleInputChangesetAddSaleDialog({
                      target: {
                        name: "dateCompromis",
                        value: date,
                      },
                    });
                  }}
                  format="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
              <Grid item></Grid>
              <Grid item></Grid>            
              <Grid item>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="select-sale-user-label">Sortie</InputLabel>
                  <Select
                    labelId="select-sale-user-label"
                    id="select-sale-user"
                    name="refSaleUser"
                    value={newSale.refSaleUser || ""}
                    onChange={handleInputChangesetAddSaleDialog}
                  >
                    {users.map((user) => (
                      <MenuItem key={user.idusers} value={user.idusers}>
                        {user.userFirstName} {user.userName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  margin="dense"
                  name="percentSaleUser"
                  label="Commission Vente"
                  type="number"
                  fullWidth
                  value={newSale.percentSaleUser || ""}
                  onChange={handleInputChangesetAddSaleDialog}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>  
              <Grid item>
                <DatePicker
                  label="Date d'acte prévu"
                  onChange={(date) => {
                    handleInputChangesetAddSaleDialog({
                      target: {
                        name: "dateActePrevu",
                        value: date,
                      },
                    });
                  }}
                  format="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  label="Date d'acte"
                  onChange={(date) => {
                    handleInputChangesetAddSaleDialog({
                      target: {
                        name: "dateActe",
                        value: date,
                      },
                    });
                  }}
                  format="DD/MM/YYYY"
                  views={["year", "month", "day"]}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddSaleDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSaveAddSaleDialog} color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VenteListe;
