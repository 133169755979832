import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from '../../makeStyle';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { 
  IconButton, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions, 
  Button, 
  TextField 
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import config from '../../config';

const host = config.host;
const idpHost = config.idpHost;


function Agences() {
  const useStyles = makeStyles({
    customHeader: {
      fontWeight: "bold",
      // Autres propriétés de style souhaitées
    },
  });
  
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedAgence, setSelectedAgence] = useState({});
  const [addAgence, setAddAgence] = useState({
        idAgence: "",
        agNom: "",
        agAdr: "",
        agCp: "",
        agVille: "",
        agTel: "",
        agMail: "",
        agWeb: "",
        agSiret: "",
        agApe: "",
        agIrc: "",
        agTva: "",
        actif: 1,
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [agences, setAgences] = useState([]);
  
  useEffect(() => {
    //const url = `${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/agences/list`;
    const url = `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/agences/list`;
    console.log(url)
    axios
      .get(url)
      .then((response) => {
        if (response.data.agenceList) {
          setAgences(response.data.agenceList);
        } else {
          console.log("Aucune donnée reçue");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleModalAddOpen = () => {
    setOpenAddDialog(true);
  };

  const handleModalAddInputChange = (e) => {
    setAddAgence({ ...addAgence, [e.target.name]: e.target.value });
  };

  const handleModalAddSave = async () => {
    try {
      const response = await axios.post(
        //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/agences/insert`,
         `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/agences/insert`,
        addAgence
      );
      console.log("Received response from API:", response);
      if (response.status === 200) {
        addAgence.idAgence = response.data.idAgence;
        setAgences(agences => [...agences, addAgence]);
        setOpenAddDialog(false);
      } else {
        console.error("Error inserting agence");
      }
    } catch (error) {
      console.error("Error inserting agence:", error);
    }
  };
  

  /*modale update*/
  const handleModalOpen = (id) => {
    const agence = agences.find((a) => a.idAgence === id);
    setSelectedAgence(agence);
    setOpen(true);
  };

  
  const handleModalClose = () => {
    setOpen(false);
  };

  const handleModalInputChange = (e) => {
    setSelectedAgence({ ...selectedAgence, [e.target.name]: e.target.value });
  };

  const handleModalSave = async () => {
    try {
      const response = await axios.put(
        //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/agences/update/${selectedAgence.idAgence}`,
        `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/agences/update/${selectedAgence.idAgence}`,        
        selectedAgence
      );
      if (response.status === 200) {
        const updatedAgences = agences.map((agence) =>
          agence.idAgence === selectedAgence.idAgence ? selectedAgence : agence
        );
        setAgences(updatedAgences);
        setOpen(false);
      } else {
        console.error("Error updating agence");
      }
    } catch (error) {
      console.error("Error updating agence:", error);
    }
  };

  /*Modal delete*/
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = async (row) => {
    try {
      selectedAgence.actif = 0   ;
      const response = await axios.put(
        //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/agences/update/${selectedAgence.idAgence}`,
        `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/agences/update/${selectedAgence.idAgence}`,
        selectedAgence
      );
      if (response.status === 200) {
        setAgences(agences.filter((agence) => agence.idAgence !== selectedAgence.idAgence));
        setOpenDeleteDialog(false);
        setSelectedAgence([]);
      } else {
        console.error("Error updating agence");
      }
    } catch (error) {
      console.error("Error updating agence:", error);
    }
  };

  const handleModalDeleteOpen = async (row) =>{
    setSelectedAgence(row)
    setOpenDeleteDialog(true);
  }
  

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: classes.customHeader,
      width: 160,
      disableColumnMenu: true, 
      disableSort: true,
      renderHeader: () => (
        <>
          <IconButton color="primary" onClick={handleModalAddOpen}>
            <AddIcon />
          </IconButton>
          Actions
        </>
      ),
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleModalOpen(params.row.idAgence)}>
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleModalDeleteOpen(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
    { field: "agNom", headerName: "Nom", width: 150 },
    { field: "agAdr", headerName: "Adresse", width: 200 },
    { field: "agCp", headerName: "Code Postal", width: 130 },
    { field: "agVille", headerName: "Ville", width: 150 },
    { field: "agTel", headerName: "Téléphone", width: 150 },
    { field: "agEmail", headerName: "Email", width: 200 },
  ];

  return (
    <div style={{ height: 800, width: "100%", paddingTop: 20 }}>
      <DataGrid
        getRowId={(row) => row.idAgence}
        rows={agences}
        columns={columns}
        pageSize={50}
        style={{ height: "100vh" }}
      />
      {
        <>
          <Dialog open={open} onClose={handleModalClose}>
            <DialogTitle>Modifier l'agence</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nom"
                type="text"
                fullWidth
                value={selectedAgence.agNom}
                onChange={handleModalInputChange}
                name="agNom"
              />
              <TextField
                margin="dense"
                id="address"
                label="Adresse"
                type="text"
                fullWidth
                value={selectedAgence.agAdr}
                onChange={handleModalInputChange}
                name="agAdr"
              />
              <TextField
                margin="dense"
                id="postalCode"
                label="Code Postal"
                type="text"
                fullWidth
                value={selectedAgence.agCp}
                onChange={handleModalInputChange}
                name="agCp"
              />
              <TextField
                margin="dense"
                id="city"
                label="Ville"
                type="text"
                fullWidth
                value={selectedAgence.agVille}
                onChange={handleModalInputChange}
                name="agVille"
              />
              <TextField
                margin="dense"
                id="phone"
                label="Téléphone"
                type="text"
                fullWidth
                value={selectedAgence.agTel}
                onChange={handleModalInputChange}
                name="agTel"
              />
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                value={selectedAgence.agEmail}
                onChange={handleModalInputChange}
                name="agEmail"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose} color="primary">
                Annuler
              </Button>
              <Button onClick={handleModalSave} color="primary">
                Enregistrer
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
            <DialogTitle>Confirmer la suppression</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Voulez-vous vraiment supprimer l'agence "{selectedAgence.agNom}"
                ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose} color="primary">
                Annuler
              </Button>
              <Button onClick={handleConfirmDelete} color="secondary">
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
            <DialogTitle>Ajouter une agence</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nom"
                type="text"
                fullWidth
                onChange={handleModalAddInputChange}
                name="agNom"
              />
              <TextField
                margin="dense"
                id="address"
                label="Adresse"
                type="text"
                fullWidth
                onChange={handleModalAddInputChange}
                name="agAdr"
              />
              <TextField
                margin="dense"
                id="postalCode"
                label="Code Postal"
                type="text"
                fullWidth
                onChange={handleModalAddInputChange}
                name="agCp"
              />
              <TextField
                margin="dense"
                id="city"
                label="Ville"
                type="text"
                fullWidth
                onChange={handleModalAddInputChange}
                name="agVille"
              />
              <TextField
                margin="dense"
                id="phone"
                label="Téléphone"
                type="text"
                fullWidth
                onChange={handleModalAddInputChange}
                name="agTel"
              />
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                onChange={handleModalAddInputChange}
                name="agEmail"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {setOpenAddDialog(false)}} color="primary">
                Annuler
              </Button>
              <Button onClick={() => {handleModalAddSave()}} color="primary">
                Enregistrer
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </div>
  );
}

export default Agences;
