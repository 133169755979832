import UserContext from './contexts/UserContext';
import axios from 'axios';
import { useEffect } from 'react';
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { Navigate, Route, Routes } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";

//import "@fontsource/krona-one"; // Import the Krona One font

import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SavingsIcon from "@mui/icons-material/Savings";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import EuroIcon from "@mui/icons-material/Euro";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import VenteListe from "./containers/VenteListe/VenteListe";
import VenteImportXls from "./containers/VenteImportXls/VenteImportXls";
import Users from "./containers/Users/Users";
import ConfigurationCommissions from "./containers/ConfigurationCommissions/ConfigurationCommissions";
import Salaires from "./containers/Salaires/Salaires";
import LoginForm from "./containers/LoginForm/LoginForm";
import Agences from "./containers/Agences/Agences";

import config from './config';

const drawerWidth = "110px";
const drawerWidthConfig = "142px";
const drawerWidthClosed = "80px";

const cfgUser = config.appUser;

const Root = styled('div')({
  display: "flex",
  height: "100%",
});

const CustomDrawer = styled(Drawer)(({ theme, open, configopen }) => ({
  width: open ? drawerWidth : drawerWidthClosed,
  flexShrink: 0,
  [theme.breakpoints.up("md")]: {
    width: open ? (configopen ? drawerWidthConfig : drawerWidth) : drawerWidthClosed,
    flexShrink: 0,
  },
}));

const NestedListItemIcon = styled(ListItemIcon)({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: "16px",
  transform: "scale(0.75)",
});

const ConfigMenuItem = ({ menuOpen }) => {
  const [configOpen, setConfigOpen] = useState(false);

  const handleClick = () => {
    setConfigOpen(!configOpen);
  };

  const configData = {
    name: "Configuration",
    icon: <SettingsIcon />,
    subItems: [
      {
        name: "Agences",
        icon: <StorefrontIcon />,
        path: "/configuration/agences",
      },
      {
        name: "Salariés",
        icon: <PeopleAltIcon />,
        path: "/configuration/users",
      },
      {
        name: "Niveaux de commission",
        icon: <SavingsIcon />,
        path: "/configuration/commissions",
      },
      {
        name: "Import Xls",
        icon: <ImportExportIcon />,
        path: "/import-xls",
      },
    ],
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{configData.icon}</ListItemIcon>
        <ListItemText
          primary={menuOpen ? configData.name : ""}
          style={{ display: menuOpen ? "block" : "none" }}
        />
        {menuOpen ? (configOpen ? <ExpandLess /> : <ExpandMore />) : null}
      </ListItem>
      <Collapse in={configOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {configData.subItems.map((item, index) => (
            <ListItem
              key={index}
              button
              component={Link}
              to={item.path}
              style={{ paddingLeft: "32px" }}
            >
              <NestedListItemIcon>
                {item.icon}
              </NestedListItemIcon>
              <ListItemText
                primary={item.name}
                style={{ display: menuOpen ? "block" : "none" }}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

function App() {
  const [menuOpen, setMenuOpen] = useState(true);
  const [configOpen, setConfigOpen] = useState(false);
  const menuWidth = menuOpen ? (configOpen ? drawerWidthConfig : drawerWidth) : drawerWidthClosed;

  const [isAuthenticated, setIsAuthenticated] = useState(false);//useState(process.env.NODE_ENV === 'development');
  const [userId, setUserId] = useState(process.env.NODE_ENV === 'development' ? cfgUser : null);

  useEffect(() => {
    const handleMenuClick = () => {
      setMenuOpen(!menuOpen);
    };

    const handleConfigClick = () => {
      setConfigOpen(!configOpen);
    };

    const token = localStorage.getItem('accessToken');

    if (token) {
      axios.get('https://your-server/verify-token', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((response) => {
          setIsAuthenticated(true);
          setUserId(response.data.userId);
        })
        .catch((error) => {
          console.error(error);
          localStorage.removeItem('accessToken');
        });
    }
  }, [menuOpen, configOpen]);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    setIsAuthenticated(false);
    setUserId(null);
  };

  const handleClick = () => {
    setConfigOpen(!configOpen);
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const getData = () => {
    return [
      {
        name: "Liste des ventes",
        icon: <PointOfSaleIcon />,
        path: "/sales",
      },
      {
        name: "Commissions",
        icon: <EuroIcon />,
        path: "/salaire",
      },
    ];
  };

  const renderMenuItems = (items, isNested = false) => {
    return items.map((item, index) => {
      if (item.subItems) {
        return (
          <React.Fragment key={index}>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={menuOpen ? item.name : ""}
                style={{ display: menuOpen ? "block" : "none" }}
              />
              {menuOpen ? (configOpen ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItem>
            <Collapse in={configOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenuItems(item.subItems, true)}
              </List>
            </Collapse>
          </React.Fragment>
        );
      } else {
        return (
          <ListItem button key={index} component={Link} to={item.path}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.name}
              style={{ display: menuOpen ? "block" : "none" }}
            />
          </ListItem>
        );
      }
    });
  };

  return (
    <UserContext.Provider value={userId}>
      <Root>
        {isAuthenticated ? (
          <>
            <CustomDrawer
              open={menuOpen}
              configopen={configOpen}
              variant="permanent"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                }}
              >
                <IconButton onClick={() => handleMenuClick(false)}>
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" style={{ paddingLeft: "16px" }}>
                  {menuOpen ? "Menu" : ""}
                </Typography>
              </div>
              <List>{renderMenuItems(getData())}</List>
              <ConfigMenuItem menuOpen={menuOpen} />
              <Divider />
              <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText
                  primary={menuOpen ? "Déconnexion" : ""}
                  style={{ display: menuOpen ? "block" : "none" }}
                />
              </ListItem>
            </CustomDrawer>
            <Box sx={{ marginLeft: menuWidth, height: "80%", width: "100%" }}>
              <Routes>
                <Route path="/sales" element={<VenteListe />} />
                <Route path="/import-xls" element={<VenteImportXls />} />
                <Route path="/salaire" element={<Salaires />} />
                <Route path="/configuration/users" element={<Users />} />
                <Route
                  path="/configuration/commissions"
                  element={<ConfigurationCommissions />}
                />
                <Route path="/configuration/agences" element={<Agences />} />
              </Routes>
            </Box>
          </>
        ) : (
          <Routes>
            <Route
              path="/login"
              element={
                <LoginForm
                  setIsAuthenticated={setIsAuthenticated}
                  setUserId={setUserId}
                />
              }
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </Root>
    </UserContext.Provider>
  );
}

export default App;
