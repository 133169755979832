import React, { useState, useEffect } from 'react';
import { makeStyles } from '../../makeStyle';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import axios from 'axios';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function ConfigurationCommissions() {
  const classes = useStyles();
  const [sales, setSales] = useState([]);

  useEffect(() => {
    axios.get('/api/sales')
      .then(response => {
        setSales(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="Liste des ventes">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">Nom</TableCell>
            <TableCell align="right">Prix</TableCell>
            <TableCell align="right">Commission</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales.map((sale) => (
            <TableRow key={sale.id}>
              <TableCell component="th" scope="row">
                {sale.id}
              </TableCell>
              <TableCell align="right">{sale.name}</TableCell>
              <TableCell align="right">{sale.price}</TableCell>
              <TableCell align="right">{sale.commission}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ConfigurationCommissions;
