import React, { useState, useEffect } from "react";
import { makeStyles } from '../../makeStyle';
import axios from "axios";

import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { FormattedNumber } from "react-intl";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl ,
  InputLabel ,
  Select,
  MenuItem 
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';

import config from "../../config";

const host = config.host;
const idpHost = config.idpHost;

function Users() {
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    customHeader: {
      fontWeight: "bold",
    },
  });
  const classes = useStyles();

  const [agenceList, setAgenceList] = useState([]);

  const [selectedUserDelete, setSelectedUserDelete] = useState({});
  const [selectedUserUpdate, setSelectedUserUpdate] = useState({});
  
  const [addUser, setAddUser] = useState({
    idusers: "",
    userFirstName: "",
    userName: "",
    userPwd: "",
    baseBrut: 0,
    primeMandat: 0,
    anciennete: 0,
    prime13Mois: 0,
    orpiAlias: "",
    refAgence: "",
    email: "",
    actif: 1,
  });
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [agences, setAgences] = useState([]);

  useEffect(() => {
    const url = //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/agences/list`;
    `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/agences/list`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.agenceList) {
          setAgences(response.data.agenceList);
        } else {
          console.log("Aucune donnée reçue");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  useEffect(() => {
    const url = //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/users/list`;
      `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/users/list`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.usersList) {
          console.log(response.data.usersList);
          setUsers(response.data.usersList);
        } else {
          console.log("Aucune donnée reçue");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /* modale add*/
  const handleModalAddInputChange = (e) => {
    setAddUser({ ...addUser, [e.target.name]: e.target.value });
  };

  const handleModalAddSave = async () => {
    try {
      const response = await axios.post(
        //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/users/insert`,
        `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/users/insert`,
        addUser
      );
      console.log("Received response from API:", response);
      if (response.status === 200) {
        addUser.idusers = response.data.idusers;
        setUsers((users) => [...users, addUser]);
        setOpenAddDialog(false);
      } else {
        console.error("Error inserting user");
      }
    } catch (error) {
      console.error("Error inserting user:", error);
    }
  };

  const handleModalAddOpen = () => {
    setOpenAddDialog(true);
  };

  const handleModalAddClose = () => {
    setOpenAddDialog(false);
  };

  /*modale update*/
  const handleModalUpdateOpen = (row) => {
    const user = users.find((u) => u.idusers === row.idusers );
    setSelectedUserUpdate(user);
    setOpenUpdate(true);
  };

  const handleModalUpdateClose = () => {
    setOpenUpdate(false);
  };

  const handleModalInputChange = (e) => {
    setSelectedUserUpdate({ ...selectedUserUpdate, [e.target.name]: e.target.value });
  };

  const handleModalUpdateSave = async () => {
    try {
      const response = await axios.put(
        //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/users/update/${selectedUserUpdate.idusers}`,
        `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/users/update/${selectedUserUpdate.idusers}`,
        selectedUserUpdate
      );
      if (response.status === 200) {
        const updatedUsers = users.map((user) =>
          user.idusers === selectedUserUpdate.idusers ? selectedUserUpdate : user
        );
        setUsers(updatedUsers);
        setOpenUpdate(false);
      } else {
        console.error("Error updating user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  /*modale delete*/

  const handleModalDeleteOpen = async (row) => {
    setSelectedUserDelete(row);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      selectedUserDelete.actif = 0;
      const response = await axios.put(
        //`${process.env.NODE_ENV === 'development'? 'http': 'http'}://${host}:3001/api/users/update/${selectedUserDelete.idusers}`,
        `${process.env.NODE_ENV === 'development'? `http://${host}:3001`: `https://${idpHost}/server`}/api/users/update/${selectedUserDelete.idusers}`,
        selectedUserDelete
      );
      if (response.status === 200) {
        setUsers(users.filter((user) => user.idusers !== selectedUserDelete.idusers));
        setOpenDeleteDialog(false);
        setSelectedUserDelete([]);
      } else {
        console.error("Error delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
  /*
  const columns = [
    { field: "userFirstName", headerName: "First name", width: 130 },
    { field: "userName", headerName: "Last name", width: 130 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 90,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.getValue("firstName") || ""} ${
          params.getValue("lastName") || ""
        }`,
    },
  ];
  */

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: classes.customHeader,
      width: 160,
      disableColumnMenu: true, 
      disableSort: true,
      renderHeader: () => (
        <>
          <IconButton color="primary" onClick={handleModalAddOpen}>
            <AddIcon />
          </IconButton>
          Actions
        </>
      ),
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleModalUpdateOpen(params.row)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleModalDeleteOpen(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
    /*{ field: "idusers", headerName: "ID", width: 100 },*/
    { field: "userFirstName", headerName: "Prénom", width: 150 },
    { field: "userName", headerName: "Nom", width: 150 },

    /*{ field: "userPwd", headerName: "Password", width: 130 },*/
    /*{ field: "baseBrut", headerName: "Base Brut", width: 180, type: "number" },
    { field: "primeMandat", headerName: "Prime Mandat", width: 180, type: "number" },
    { field: "anciennete", headerName: "Ancienneté", width: 180, type: "number" },
    { field: "prime13Mois", headerName: "Prime 13 Mois", width: 180, type: "number" },
    { field: "createDate", headerName: "Date de création", width: 150 },
    { field: "updateDate", headerName: "Date de mise à jour", width: 150 },*/
    {
      field: "refAgence",
      headerName: "Agence",
      width: 150,
      valueGetter: (params) => {
        // Trouvez l'agence correspondante à l'idAgence de cette ligne
        const correspondingAgence = agences.find(
          (agence) => agence.idAgence === params.row.refAgence
        );
        // Si une agence correspondante a été trouvée, retournez son agNom, sinon retournez une chaîne vide
        return correspondingAgence ? correspondingAgence.agNom : "";
      },
    },
    { field: "orpiAlias", headerName: "Orpi Alias", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
  ];

  return (
    <div style={{ height: 800, width: "100%", paddingTop: 20 }}>
      <DataGrid
        getRowId={(row) => row.idusers}
        rows={users}
        columns={columns}
        pageSize={50}
        style={{ height: "100vh" }}
      />
      {
        <>
          <Dialog open={openUpdate} onClose={handleModalUpdateClose}>
            <DialogTitle>Modifier l'utilisateur</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="firstName"
                label="Prénom"
                type="text"
                fullWidth
                value={selectedUserUpdate.userFirstName}
                onChange={handleModalInputChange}
                name="userFirstName"
              />
              <TextField
                margin="dense"
                id="lastName"
                label="Nom"
                type="text"
                fullWidth
                value={selectedUserUpdate.userName}
                onChange={handleModalInputChange}
                name="userName"
              />
              {/*<TextField
                margin="dense"
                id="password"
                label="Mot de passe"
                type="password"
                fullWidth
                value={selectedUserUpdate.userPwd}
                onChange={handleModalInputChange}
                name="userPwd"
              />
              <TextField
                margin="dense"
                id="baseSalary"
                label="Salaire de base"
                type="text"
                fullWidth
                value={selectedUserUpdate.baseBrut}
                onChange={handleModalInputChange}
                name="baseBrut"
              />
              <TextField
                margin="dense"
                id="mandateBonus"
                label="Prime de mandat"
                type="text"
                fullWidth
                value={selectedUserUpdate.primeMandat}
                onChange={handleModalInputChange}
                name="primeMandat"
              />
              <TextField
                margin="dense"
                id="seniority"
                label="Ancienneté"
                type="text"
                fullWidth
                value={selectedUserUpdate.anciennete}
                onChange={handleModalInputChange}
                name="anciennete"
              />
              <TextField
                margin="dense"
                id="thirteenthMonthBonus"
                label="Prime du 13ème mois"
                type="text"
                fullWidth
                value={selectedUserUpdate.prime13Mois}
                onChange={handleModalInputChange}
                name="prime13Mois"
              />*/}
              <TextField
                margin="dense"
                id="orpiAlias"
                label="Alias ORPI"
                type="text"
                fullWidth
                value={selectedUserUpdate.orpiAlias}
                onChange={handleModalInputChange}
                name="orpiAlias"
              />
              <FormControl fullWidth margin="dense">
                <InputLabel id="agencyRefLabel">Agence</InputLabel>
                <Select
                  labelId="agencyRefLabel"
                  id="agencyRef"
                  value={selectedUserUpdate.refAgence}
                  onChange={handleModalInputChange}
                  name="refAgence"
                >
                  {agences.map((agence) => (
                    <MenuItem key={agence.idAgence} value={agence.idAgence}>
                      {agence.agNom}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                value={selectedUserUpdate.email}
                onChange={handleModalInputChange}
                name="email"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalUpdateClose} color="primary">
                Annuler
              </Button>
              <Button onClick={handleModalUpdateSave} color="primary">
                Enregistrer
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
            <DialogTitle>Confirmer la suppression</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Voulez-vous vraiment supprimer l'utilisateur "
                {selectedUserDelete.userFirstName} {selectedUserDelete.userName}
                " ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteDialogClose} color="primary">
                Annuler
              </Button>
              <Button onClick={handleConfirmDelete} color="secondary">
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
            <DialogTitle>Ajouter un utilisateur</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="firstName"
                
                label="Prénom"
                type="text"
                fullWidth
                value={addUser.userFirstName}
                onChange={handleModalAddInputChange}
                name="userFirstName"
              />
              <TextField
                margin="dense"
                id="lastName"
                label="Nom"
                type="text"
                fullWidth
                value={addUser.userName}
                onChange={handleModalAddInputChange}
                name="userName"
              />
              {/*<TextField
                margin="dense"
                id="password"
                label="Mot de passe"
                type="password"
                fullWidth
                value={addUser.userPwd}
                onChange={handleModalAddInputChange}
                name="userPwd"
              />
              <TextField
                margin="dense"
                id="baseSalary"
                label="Salaire de base"
                type="text"
                fullWidth
                value={addUser.baseBrut}
                onChange={handleModalAddInputChange}
                name="baseBrut"
              />
              <TextField
                margin="dense"
                id="mandateBonus"
                label="Prime de mandat"
                type="text"
                fullWidth
                value={addUser.primeMandat}
                onChange={handleModalAddInputChange}
                name="primeMandat"
              />
              <TextField
                margin="dense"
                id="seniority"
                label="Ancienneté"
                type="text"
                fullWidth
                value={addUser.anciennete}
                onChange={handleModalAddInputChange}
                name="anciennete"
              />
              <TextField
                margin="dense"
                id="thirteenthMonthBonus"
                label="Prime du 13ème mois"
                type="text"
                fullWidth
                value={addUser.prime13Mois}
                onChange={handleModalAddInputChange}
                name="prime13Mois"
              />*/}
              <TextField
                margin="dense"
                id="orpiAlias"
                label="Alias ORPI"
                type="text"
                fullWidth
                value={addUser.orpiAlias}
                onChange={handleModalAddInputChange}
                name="orpiAlias"
              />
              <FormControl fullWidth margin="dense">
                <InputLabel id="agencyRefLabel">Agence</InputLabel>
                <Select
                  labelId="agencyRefLabel"
                  id="agencyRef"
                  value={addUser.refAgence}
                  onChange={handleModalAddInputChange}
                  name="refAgence"
                >
                  {agences.map((agence) => (
                    <MenuItem key={agence.idAgence} value={agence.idAgence}>
                      {agence.agNom}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                value={addUser.email}
                onChange={handleModalAddInputChange}
                name="email"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleModalAddClose}
                color="primary"
              >
                Annuler
              </Button>
              <Button
                onClick={handleModalAddSave}
                color="primary"
              >
                Enregistrer
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </div>
  );
}

export default Users;
